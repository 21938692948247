import React from "react";

function Combos({ devCombo, officeCombo }) {
  const devData = ["110011", "001100", "101010", "010101", "100010", "010001"];
  const ofcData = ["11100111", "00011000", "10100101", "01011010", "01000010"];

  const DevCardCombo = (item) => {
    return (
      <div className="combos-card" onClick={() => devCombo(item)}>
        <div className="combos-column">
          <div
            style={{ backgroundColor: item[0] === "1" ? "yellow" : "white" }}
            className="combos-circle"
          />
          <div
            style={{ backgroundColor: item[2] === "1" ? "yellow" : "white" }}
            className="combos-circle"
          />
          <div
            style={{ backgroundColor: item[4] === "1" ? "yellow" : "white" }}
            className="combos-circle"
          />
        </div>
        <div className="combos-column">
          <div
            style={{ backgroundColor: item[1] === "1" ? "yellow" : "white" }}
            className="combos-circle"
          />
          <div
            style={{ backgroundColor: item[3] === "1" ? "yellow" : "white" }}
            className="combos-circle"
          />
          <div
            style={{ backgroundColor: item[5] === "1" ? "yellow" : "white" }}
            className="combos-circle"
          />
        </div>
      </div>
    );
  };

  const OfficeCardCombo = (item) => {
    return (
      <div className="combos-card" onClick={() => officeCombo(item)}>
        <div className="office-map-circel">
          <div className="office-circle-row">
            <div
              style={{ backgroundColor: item[0] === "1" ? "yellow" : "white" }}
              className="combos-circle"
            />
            <div
              style={{ backgroundColor: item[1] === "1" ? "yellow" : "white" }}
              className="combos-circle"
            />
            <div
              style={{ backgroundColor: item[2] === "1" ? "yellow" : "white" }}
              className="combos-circle"
            />
          </div>
          <div className="office-circle-column">
            <div
              style={{ backgroundColor: item[3] === "1" ? "yellow" : "white" }}
              className="combos-circle one"
            />
            <div
              style={{ backgroundColor: item[4] === "1" ? "yellow" : "white" }}
              className="combos-circle two"
            />
          </div>
          <div className="office-circle-row">
            <div
              style={{ backgroundColor: item[5] === "1" ? "yellow" : "white" }}
              className="combos-circle"
            />
            <div
              style={{ backgroundColor: item[6] === "1" ? "yellow" : "white" }}
              className="combos-circle"
            />
            <div
              style={{ backgroundColor: item[7] === "1" ? "yellow" : "white" }}
              className="combos-circle"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="combos-container">
      <div className="combos-title">Development Area</div>
      {window.innerWidth > 480 ? (
        <div
          className="combos-row"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "2rem",
            marginRight: "2rem",
          }}
        >
          {devData.map(DevCardCombo)}
        </div>
      ) : (
        <div className="phone-combo-div">{devData.map(DevCardCombo)}</div>
      )}
      <div className="combos-title">Office Area</div>
      {window.innerWidth > 480 ? (
        <div
          className="combos-row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: "2rem",
            marginRight: "2rem",
          }}
        >
          {ofcData.map(OfficeCardCombo)}
        </div>
      ) : (
        <div className="phone-combo-div">{ofcData.map(OfficeCardCombo)}</div>
      )}
    </div>
  );
}

export default Combos;
