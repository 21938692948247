import React from "react";
import { Fan } from "../assets";
import "./components.css";

function Mapping({ lightsState, toggleLights }) {
  const renderDevLight = (item) => (
    <div
      className="circle"
      style={{
        backgroundColor: lightsState[item] === "1" ? "yellow" : "white",
      }}
      onClick={() => toggleLights(item)}
    />
  );

  const renderOfficeLight = (item) => (
    <div
      className="office-circle"
      style={{
        backgroundColor: lightsState[item] === "1" ? "yellow" : "white",
      }}
      onClick={() => toggleLights(item)}
    />
  );

  return (
    <div className="map-container">
      <div className="map-circel-container">
        <div className="map-circel-div">
          {renderDevLight(0)}
          {renderDevLight(2)}
          {renderDevLight(4)}
        </div>
        <div className="map-circel-div">
          <img className="fan-img" src={Fan} />
          <img className="fan-img" src={Fan} />
        </div>
        <div className="map-circel-div">
          {renderDevLight(1)}
          {renderDevLight(3)}
          {renderDevLight(5)}
        </div>
      </div>
      <div style={{ height: "20px", width: "100%", backgroundColor: "gray" }} />
      <div className="office-map-circle">
        <div className="office-circle-row">
          {renderOfficeLight(6)}
          {renderOfficeLight(7)}
          {renderOfficeLight(8)}
        </div>
        <div className="office-circle-column">
          {renderOfficeLight(9)}
          <img
            className={
              lightsState[10] === "1"
                ? "office-fan-img clicked"
                : "office-fan-img"
            }
            src={Fan}
            onClick={() => toggleLights(10)}
          />
          {renderOfficeLight(11)}
        </div>
        <div className="office-circle-row">
          {renderOfficeLight(12)}
          {renderOfficeLight(13)}
          {renderOfficeLight(14)}
        </div>
      </div>
    </div>
  );
}

export default Mapping;
