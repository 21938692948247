import { useEffect, useRef, useState } from "react";
import "./App.css";
import { AllLight, Combos, Mapping } from "./components";

function App() {
  const [activeTab, setActiveTab] = useState(0);
  const [lightsState, setLightsState] = useState("0000000000000000");
  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket(`ws://192.168.23.13/`);
    ws.current.onmessage = (e) => {
      setLightsState(e.data);
    };

    return () => {
      if (ws.current) {
        ws.current?.close();
      }
    };
  }, []);

  const sendWebSocketMessage = (message) => {
    ws.current?.send(message);
  };

  const toggleLights = (index) => {
    const newState =
      lightsState.slice(0, index) +
      (lightsState[index] === "1" ? "0" : "1") +
      lightsState.slice(index + 1, 16);
    sendWebSocketMessage(newState);
  };

  const devCombo = (state) => {
    const newState = state + lightsState.slice(6, 16);
    sendWebSocketMessage(newState);
  };
  const officeCombo = (state) => {
    const newState =
      lightsState.slice(0, 6) +
      state.slice(0, 4) +
      lightsState[10] +
      state.slice(4, 8) +
      "0";
    sendWebSocketMessage(newState);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const toggleAllDevelopmentArea = (value) => {
    const newState = (!value ? "000000" : "111111") + lightsState.slice(6, 16);
    sendWebSocketMessage(newState);
  };
  const toggleAllOfficeArea = (value) => {
    const newState =
      lightsState.slice(0, 6) +
      (!value
        ? "0000" + lightsState[10] + "0000"
        : "1111" + lightsState[10] + "1111") +
      lightsState[15];
    sendWebSocketMessage(newState);
  };

  const allLightOn = () => {
    sendWebSocketMessage("1111111111111111");
  };

  const allLightOff = () => {
    sendWebSocketMessage("0000000000000000");
  };

  return (
    <div>
      <div className="tab-bar">
        <div className="tab">
          <button
            className={activeTab === 0 ? "active" : ""}
            onClick={() => handleTabClick(0)}
          >
            Mapping
          </button>
        </div>
        <div className="tab">
          <button
            className={activeTab === 1 ? "active" : ""}
            onClick={() => handleTabClick(1)}
          >
            Combos
          </button>
        </div>
        <div className="tab">
          <button
            className={activeTab === 2 ? "active" : ""}
            onClick={() => handleTabClick(2)}
          >
            All Light
          </button>
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <Mapping lightsState={lightsState} toggleLights={toggleLights} />
        )}
        {activeTab === 1 && (
          <Combos devCombo={devCombo} officeCombo={officeCombo} />
        )}
        {activeTab === 2 && (
          <AllLight
            toggleAllDevelopmentArea={toggleAllDevelopmentArea}
            toggleAllOfficeArea={toggleAllOfficeArea}
            allLightOn={allLightOn}
            allLightOff={allLightOff}
          />
        )}
      </div>
    </div>
  );
}

export default App;
