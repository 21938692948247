import React from "react";

function AllLight({
  toggleAllOfficeArea,
  toggleAllDevelopmentArea,
  allLightOn,
  allLightOff,
}) {
  const LightCard = ({ textOne, textTwo, onClick }) => {
    return (
      <div>
        {window.innerWidth > 480 ? (
          <div className="light-card" onClick={onClick}>
            <span className="light-text">{textOne}</span>
            <span className="light-text">{textTwo}</span>
          </div>
        ) : (
          <div className="res-light-card" onClick={onClick}>
            <span className="res-light-text">{textOne}</span>
            <span className="res-light-text">{textTwo}</span>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="light-container">
      <div className="combos-row ">
        <LightCard
          onClick={() => toggleAllDevelopmentArea(true)}
          textOne={"LIT UP 🔥"}
          textTwo={"DEVELOPMENT AREA"}
        />
        <LightCard
          onClick={() => toggleAllOfficeArea(true)}
          textOne={"LIT UP 🔥"}
          textTwo={"CABIN AREA"}
        />
      </div>
      <div className="combos-row">
        <LightCard
          onClick={() => toggleAllDevelopmentArea(false)}
          textOne={"SHADOW MODE 🥷🏻"}
          textTwo={"DEVELOPMENT AREA"}
        />
        <LightCard
          onClick={() => toggleAllOfficeArea(false)}
          textOne={"SHADOW MODE 🥷🏻"}
          textTwo={"CABIN AREA"}
        />
      </div>
      <div className="combos-row">
        <LightCard onClick={allLightOn} textOne={"ALL LIGHT AND FAN ON 🔥"} />
        <LightCard onClick={allLightOff} textOne={"ALL LIGHT AND FAN OFF 🥷🏻"} />
      </div>
    </div>
  );
}

export default AllLight;
